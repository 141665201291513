import React from 'react'
import Juror from '../../components/juror'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from './leftMenu'

const McCartheyJury = () => {
  return (
    <Layout>
    <SEO title="National Student Essay Competition | Jury" />

    <div className="subpageContainer">

      <LeftMenu active="jury" />
      <div className="body">
         
        <h2 style={{marginTop: '55px'}}> 2020 Utah Essay Competition Jury</h2>
        <div className="jurors">
        <Juror name="Sarah J. McCarthey" title="Chair &amp; Foundation Trustee" />
        <Juror name="Thomas K. McCarthey" title="Vice-chair & Foundation Trustee" />
        <Juror name="Lois Collins" title="Deseret News reporter and columnist" />
        <Juror name="Mary Dickson" title="Playwright, Host of Contact on PBS Utah" />
        <Juror name="Lex Hemphill" title="Former Salt Lake Tribune sportswriter, columnist and editorial writer" />
        <Juror name="Nancy Melich" title="Former Salt Lake Tribune reporter. Literary Seminar Director, Utah Shakespeare Festival" />
        <Juror name="Terry Orme" title="Former Salt Lake Tribune editor and publisher" />


        </div>
        </div>
      </div>
    </Layout>
  )
}

export default McCartheyJury
